$blue: #0062ff;
$yellow: #ffb700;
$gray: #141414;

$headerHeight: 65px;

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;

  font-family: freight-sans-pro, -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif;
  font-weight: 400;

  font-size: 18px;

  @media (min-width: 800px) {
    p {
      font-size: 29px;
    }
  }
}

h1 {
  font-size: 10vmin;
  font-weight: 300;
  line-height: 1.4;

  @media (min-width: 1000px) {
    font-size: 71px;
  }
}

.text--centered {
  text-align: center;
}

.main-page {
  position: relative;
  // height: calc(100% - #{$headerHeight});
  height: 100%;

  h1 {
    margin-top: 1.34em;
  }
}

.speaker-page {
  height: 100%;
  padding: 1em;

  background-color: #141414;
  color: white;

  h1 {
    margin: 0;
  }

  p {
    margin: 1.6em 0;
  }

  .text--underline {
    border-color: rgba(255, 255, 255, 0.25);
  }
}

.flex {
  display: flex;
}

.flex--column {
  flex-direction: column;
}

.flex--center {
  justify-content: center;
  align-items: center;
}

.button {
  display: inline-block;
  border: none;
  margin: 0;
  text-decoration: none;
  background: transparent;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    //todo
  }

  &:focus {
    // todo
  }

  &:active {
  }

  border: none;

  border-radius: 5px;
  padding: 0.45em 1.4em;
}

@mixin textUnderline($underlineColor) {
  border-bottom: 3px dashed $underlineColor;
  display: inline-block;
  line-height: 1;
}

// can be used to cover text underline around descenders
@mixin textShadow($size: 2px, $color: white) {
  text-shadow: $size $size $color, $size -$size $color, -$size $size $color,
    -$size -$size $color;
}

.text--underline {
  @include textUnderline($yellow);
}

.button--shadow {
  box-shadow: 7px 8px 24px rgba(0, 0, 0, 0.15);

  transition: background 250ms ease-in-out, transform 150ms ease,
    box-shadow 250ms ease-in-out;

  &:hover,
  &:focus {
    box-shadow: 3px 4px 24px rgba(0, 0, 0, 0.35);
  }
}

.button--blue {
  background: $blue;
  border: 2px solid $blue;
  font-weight: 500;
  color: white;
}

.button--ghost {
  color: inherit;
  border: 2px solid currentColor;
  font-weight: 500;
}

.button--gray {
  background: $gray;
  font-weight: 500;
  color: white;
  border: 2px solid currentColor;

  &:hover {
    color: $yellow;
  }
}

.button--transparent {
  font-weight: normal;
  color: #111;

  &:hover {
    text-decoration: underline;
  }
}

.svg-background {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.logo {
  position: absolute;
  top: 0;
  height: 20vmin;
  margin: 50px 0 0 50px;
}

.header {
  height: $headerHeight;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
}

.sponsors {
  padding: 5vmin;
  background: $gray;
  span {
    display: block;
    color: rgba(255, 255, 255, 0.25);
    margin-bottom: 1.6em;
    font-weight: 300;
  }
  margin-top: -1px; // I'm sorry. That's workaround for visual glitch on phone screens.
}

.sponsors__logos-grid {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;

  @media (min-width: 800px) {
    height: 260px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
}

.semi-transparent {
  opacity: 0.25;
  transition: opacity 250ms ease;

  &:hover,
  &:focus,
  &:active {
    opacity: 1;
  }
}

.focus-border-bottom {
  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
    border-bottom-color: $yellow;
  }
}

.sponsors__logo {
  @extend .semi-transparent;

  > img {
    margin: 40px 0;
    height: 50px;

    @media (min-width: 400px) {
      height: 60px;
    }
  }

  &.sponsors__logo--young-skilled {
    > img {
      height: 80px;
      @media (min-width: 400px) {
        height: 90px;
      }
      margin: 0;
    }
  }
}

.about-page {
  background: $yellow;

  min-height: 100%;
  padding: 3em;

  position: relative;

  .about-page__particles-bg {
    background-image: url("./gfx/about-page-bg.svg");
    background-size: cover;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .about-page__ts-letters-bg {
    bottom: 5%;
    right: 10px;
    width: 70%;
    height: 70%;
    position: absolute;
  }

  .text--underline {
    border-color: white;
  }

  .about-page__foreground {
    position: relative;

    p {
      max-width: 100%;
      width: 22em;
    }
  }

  .attend-a-meetup-button {
    margin-top: 2.5em;
  }
}

.knowledge-bombs {
  min-height: 100%;
  padding: 5vmin;
  display: flex;
  flex-direction: column;
  background-image: url("./gfx/knowledge-bombs.svg");
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 1200px) {
    flex-direction: row;
  }

  .half {
    display: flex;
    align-items: center;
    margin: 7vmin 5vmin;
    flex: 1;

    &.call-to-action {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & .headline {
        white-space: nowrap;
        margin: 0.25em 0;
      }
    }

    &.twitter-feed {
      justify-content: center;

      iframe {
        box-shadow: 16px 15px 50px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.footer {
  padding: 0 5vmin 5em 5vmin;
  background: $gray;
  color: white;
}

.footer__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;
  border-top: 1px solid rgba(255, 255, 255, 0.15);

  @media (min-width: 600px) {
    flex-direction: row;
  }
}

.footer__nav {
  margin-top: 2em;
  a {
    @extend .semi-transparent;
    @extend .focus-border-bottom;

    font-weight: 300;
    margin-right: 3em;
    color: white;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    display: flex;

    a {
      margin-top: 2em;
      margin-right: 0;
    }

    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.footer__logo {
  flex: 1;
  margin-bottom: 9px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    margin-bottom: 3em;
  }
}

.social-icons {
  height: 50px;

  --size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    @extend .focus-border-bottom;
    margin-left: 2em;
    width: var(--size);

    > img {
      max-height: var(--size);
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .icon-twitter {
    width: calc(var(--size) + 2px);
    padding-top: 2px;
  }
}
